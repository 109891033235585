@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(54, 0, 153);
}


body {
    background-color: #FFFFFF;
    width: 100%;
    overflow-x: hidden;
}
.log {
    background-color: #6236FF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.card {
    width: 400px;
    height: 520px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 20px 0px;
    padding: 0 50px;
}

.logoo {
    width: 50px;
    padding:20px 0px;
}

.bigImage {
    width: 80%;
}

h2 {
    font-family: 'Lora', serif;
    margin-bottom: 10px;
}

h3,h5 {
    padding-bottom: 10px;
    line-height: 20px;
    font-family: 'Lato', sans-serif;
}

button {
    width: 150px;
    height: 30px;
    background-color: white;
    outline: none;
    border: none;
    cursor: pointer;
    margin:20px 0px;
}

button img {
    width: 100%;
}

button img:hover {
    box-shadow: 1px 1px 4px rgb(132, 123, 255);
}

.footer {
    margin: 20px 0px;
}

@media only screen and (max-width: 600px) {
    .card {
        width: 80%;
    }
  }
.cardd {
    width: 300px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background-color: #F6F3FF; 
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 20px;
}

.big {
    width: 100%;
}

.avatar {
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.avatar h5 {
    padding-left: 10px;
    margin-top: 10px;
}

p {
    padding: 0px 20px;
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 19.1px;
    color: #666666;

}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    min-height: 100vh;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
    flex-direction: column;
    align-items: center;
}

.modal h3 {
    color: white;
    cursor: pointer;
    text-align: right;
    transform: translateX(180px);
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .modal h3 {
        transform: translateX(120px);
    }

    .modal h2 {
        opacity: 0;
    }

  }
.carte {
    width: 400px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .carte {
        width: 80%;
    }
  }
.home {
    width: 100%;
    min-height: 100vh;
    font-family: 'Nunito Sans', sans-serif;}

.Nav {
    width: 100%;
    height: 70px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.logo {
    display: flex;
    width: 170px;
    height: 70px;
    align-items: center;
    justify-content: space-between;
}

.logo h3 {
    line-height: 70px;
    margin-top: 10px;
}
.logo img {
    width: 50px;
}

h4 {
    color: rgb(109, 109, 109);
}

.sub {
    width: 100%;
    font-size: 24px;
    line-height: 22px;
}
.container {
    width: 70%;
    margin: 30px auto;    
}

.container h3 {
    font-weight: 800;
    font-size: 34px;
    margin-bottom: 20px;
}

.grid {
    margin: 40px auto;
    -webkit-column-count: auto;
            column-count: auto;
    -webkit-column-width: 300px;
            column-width: 300px;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}
@media only screen and (max-width: 800px) {
    .container {
        width: 80%;
    }
}
  
@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .container h3 {
        padding-left: 20px;
        font-size: 20px;
        margin-bottom: 0px;
    }

    .logo img {
        width: 40px;
        transform: translateY(8px) translateX(-9px);
    }

    .logo h3 {
        transform: translateX(-20px) translateY(8px);
    }

    .grid {
        -webkit-column-count: 1;
                column-count: 1
    }
    .Nav {
        padding: 0px 30px;
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    .Nav h4 {
        transform: translateY(8px);
    }
    .sub {
        padding-left: 20px;
        font-size: 18px;
    }
    .shoutout {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
  }

  .shoutout {
    width: 100%;
      -webkit-column-count: auto;
              column-count: auto;
      -webkit-column-width: 300px;
              column-width: 300px;
      grid-column-gap: 10px;
      -webkit-column-gap: 10px;
              column-gap: 10px;
      padding: 0px 0px;
  }
.shout {
    width: 300px;
    background: #F6F3FF;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.shout p {
    padding: 0;
    margin-top: 10px;
    font-size: 14px;
    line-height: 19.1px;
    color: #666666;

}
