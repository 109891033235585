@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

.log {
    background-color: #6236FF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.card {
    width: 400px;
    height: 520px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 20px 0px;
    padding: 0 50px;
}

.logoo {
    width: 50px;
    padding:20px 0px;
}

.bigImage {
    width: 80%;
}

h2 {
    font-family: 'Lora', serif;
    margin-bottom: 10px;
}

h3,h5 {
    padding-bottom: 10px;
    line-height: 20px;
    font-family: 'Lato', sans-serif;
}

button {
    width: 150px;
    height: 30px;
    background-color: white;
    outline: none;
    border: none;
    cursor: pointer;
    margin:20px 0px;
}

button img {
    width: 100%;
}

button img:hover {
    box-shadow: 1px 1px 4px rgb(132, 123, 255);
}

.footer {
    margin: 20px 0px;
}

@media only screen and (max-width: 600px) {
    .card {
        width: 80%;
    }
  }