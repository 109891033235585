@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');


.home {
    width: 100%;
    min-height: 100vh;
    font-family: 'Nunito Sans', sans-serif;}

.Nav {
    width: 100%;
    height: 70px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    position: sticky;
    top: 0;
}

.logo {
    display: flex;
    width: 170px;
    height: 70px;
    align-items: center;
    justify-content: space-between;
}

.logo h3 {
    line-height: 70px;
    margin-top: 10px;
}
.logo img {
    width: 50px;
}

h4 {
    color: rgb(109, 109, 109);
}

.sub {
    width: 100%;
    font-size: 24px;
    line-height: 22px;
}
.container {
    width: 70%;
    margin: 30px auto;    
}

.container h3 {
    font-weight: 800;
    font-size: 34px;
    margin-bottom: 20px;
}

.grid {
    margin: 40px auto;
    column-count: auto;
    column-width: 300px;
    column-gap: 5px;
    height: max-content;
}
@media only screen and (max-width: 800px) {
    .container {
        width: 80%;
    }
}
  
@media only screen and (max-width: 600px) {
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .container h3 {
        padding-left: 20px;
        font-size: 20px;
        margin-bottom: 0px;
    }

    .logo img {
        width: 40px;
        transform: translateY(8px) translateX(-9px);
    }

    .logo h3 {
        transform: translateX(-20px) translateY(8px);
    }

    .grid {
        column-count: 1
    }
    .Nav {
        padding: 0px 30px;
        width: 100%;
        position: sticky;
        top: 0;
    }

    .Nav h4 {
        transform: translateY(8px);
    }
    .sub {
        padding-left: 20px;
        font-size: 18px;
    }
    .shoutout {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
  }

  .shoutout {
    width: 100%;
      column-count: auto;
      column-width: 300px;
      column-gap: 10px;
      padding: 0px 0px;
  }