.cardd {
    width: 300px;
    height: max-content;
    background-color: #F6F3FF; 
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 20px;
}

.big {
    width: 100%;
}

.avatar {
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.avatar h5 {
    padding-left: 10px;
    margin-top: 10px;
}

p {
    padding: 0px 20px;
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 19.1px;
    color: #666666;

}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    min-height: 100vh;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
    flex-direction: column;
    align-items: center;
}

.modal h3 {
    color: white;
    cursor: pointer;
    text-align: right;
    transform: translateX(180px);
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .modal h3 {
        transform: translateX(120px);
    }

    .modal h2 {
        opacity: 0;
    }

  }