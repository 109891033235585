.shout {
    width: 300px;
    background: #F6F3FF;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.shout p {
    padding: 0;
    margin-top: 10px;
    font-size: 14px;
    line-height: 19.1px;
    color: #666666;

}