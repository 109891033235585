.carte {
    width: 400px;
    height: max-content;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .carte {
        width: 80%;
    }
  }